<div class="sec mar-above-big">
    <div class="hero-sec">
        <h1 class="h1 dsv">
            Welcome to DISHIVI
            <span class="text-span-28" style="color:#5865F2">
                <br>
                WHERE
            </span>
            <span>
                <br>
                technology meets innovation
            </span>
        </h1>
        <div class="wrapper-title-subtitle">
            <div class="body-text centered" style="display: block;">At DISHIVI, we excel at <span
                    class="text-span-47">software
                    product and platform engineering</span> and work with our cloud-forward enterprise clients to
                develop and modernize their platforms and digital ecosystems.
            </div>
        </div>
        <div class="button centered m-40 quick-enquiry">
            <a href="#" class="btn-site developement d-inline-block">
                <div class="body-text-bold dsv black">quick inquiry</div>
            </a>
        </div>
        <div class="hero-image"><img src="../../../assets/images/softwareenginnering.jpg"
                alt="DISHIVI Cloud Application Development" class="image"></div>
    </div>
</div>
<div class="sec color">
    <div class="new-container color">
        <div class="with-dishivi-you-can">
            <div class="wrapper-title">
                <h2 class="h2 centered dsv">With dishivi, you can</h2>
            </div>
            <div class="wrapper-cards-dishivi m-40">
                <div class="grid-one text-block" class="dishivi-card">
                    <img src="../../../assets/images/service/1.svg" loading="lazy" class="grid-one mb-4" alt="dishivi">
                    <div class="grid-one" class="text-block centered"><span class="text-span-48">Architect</span> A
                        cloud-FORWARD strategy</div>
                </div>
                <div class="grid-one text-block" class="dishivi-card">
                    <img src="../../../assets/images/service/2.svg" loading="lazy" class="grid-one mb-4" alt="">
                    <div class="grid-one" class="text-block centered"><span class="text-span-49">DEVELOP</span>&nbsp;a
                        robust DIGITAL&nbsp;ECOSYSTEM
                    </div>
                </div>
                <div class="grid-one text-block" class="dishivi-card">
                    <img src="../../../assets/images/service/3.svg" loading="lazy" class="grid-one mb-4" alt="">
                    <div class="grid-one" class="text-block centered"><span
                            class="text-span-50">ACCELERATE</span>&nbsp;DIGITAL TRANSFORMATION</div>
                </div>
                <div class="grid-one text-block" class="dishivi-card">
                    <img src="../../../assets/images/service/4.svg" loading="lazy" class="grid-one mb-4" alt="">
                    <div class="grid-one" class="text-block centered"><span
                            class="text-span-51">AUTOMATE</span>&nbsp;YOUR DIGITAL&nbsp;OPERATIONS </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="sec m-120 m-60 overflow-hidden">
    <div class="">
        <div class="wrapper-title">
            <h2 class="h2 centered dsv"><strong class="bold-text-2">OUR CLIENTS</strong></h2>
        </div>
        <div class="">
            <div class=" zigzag-body">
                <div class="slider" slickContainer #slickController="slick" [slickConfig]="config">
                    <ng-container *ngIf="slickController.initialize">
                        <div slickItem class="carousel">
                            <div class="row align-items-center customer-row">
                                <div class="col-lg-6">
                                    <div class="zigzag-content">
                                        <div style="display: flex;">
                                            <img src="../../../assets/images/ycurb/logo.png" height="100%" />
                                            <h3 style="margin: 3px 0 0px 10px;">YCurb</h3>
                                        </div>
                                        <h3 class="h3 case-study font-weight-bold" style="margin-top: 10px;">
                                            Customized Curb Management System
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-lg-6 customer-img">
                                    <div class="text-center mx-auto mb-4">
                                        <img alt="Image Alternative Text" class="img-fluid mx-auto"
                                            src="../../../assets/images/ycurb/laptop.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div slickItem class="carousel">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="zigzag-content">
                                        <div style="display: flex;">
                                            <img src="../../../assets/images/ycurb/wheatonadvisorsLogo.png"
                                                height="100%" />
                                        </div>
                                        <h3 class="h3 case-study font-weight-bold" style="margin-top: 10px;">
                                            Financial Advisory and Management Consultancy
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-lg-6 customer-img">
                                    <div class="text-center mx-auto">
                                        <!-- <img class=" img-fluid mx-auto " src="../../../assets/images/mobility/mobility2.png"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- <div class="button centered m-40"><a href="#" class="btn-site developement d-inline-block">
                <div class="body-text-bold dsv black">Show more case studies</div>
            </a></div> -->
    </div>
</div>
<div class="sec mb-5">
    <div class="wrapper-title">
        <h2 class="h2 centered dsv"><strong>PRIVILEGED to SERVE</strong></h2>
    </div>
    <div class="wrapper-serve m-40 px-4">
        <div clas="grid-one text-block" class="serve-block it">
            <div class="title-sub">
                <div class="text-block-2">TECH StartupS</div>
                <div class="body-text">We have cutting-edge skills to serve top-tier VC-funded startups to co-innovate
                    on their MVP&nbsp;development. We are agile and resilient to maximize success and value for our
                    startup clients.</div>
            </div>
        </div>
        <div clas="grid-one text-block" class="serve-block">
            <div class="title-sub">
                <div class="text-block-2">PRODUCT&nbsp;COMPANIES</div>
                <div class="body-text">We have well-architected product engineering expertise that helps ISVs accelerate
                    their product roadmap. Our industry domain knowledge can modernize product portfolios and digital
                    ecosystems.</div>
            </div>
        </div>
        <div clas="grid-one text-block" class="serve-block">
            <div class="title-sub">
                <div class="text-block-2">digital businesses</div>
                <div class="body-text">We transform legacy businesses into digital businesses by developing modern mb-4
                    digital ecosystems, which become a competitive advantage for our cloud-forward clients.</div>
            </div>
        </div>
        <div clas="grid-one text-block" class="serve-block">
            <div class="title-sub">
                <div class="text-block-2">GLOBAL ENTERPRISES</div>
                <div class="body-text">We work with mid-sized and Fortune 500 enterprises in the digital transformation
                    space to enhance their edge in the digital ecosystem with API-first platforms and AI/ML-powered data
                    analytics.</div>
            </div>
        </div>
    </div>
</div>
<div class="ma-120">
    <section class="home-technologies">
        <div class="container">
            <h2 class="mb-0">Technologies we work with</h2>
            <p class="mb-0">Leverage our Dev Team’s years of expertise in the following technologies.</p>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="tab">
                    <a class="nav-link active" data-toggle="tab" href="#tabs-1">Mobile</a>
                </li>
                <li class="nav-item" role="tab">
                    <a class="nav-link" data-toggle="tab" href="#tabs-2">Backend</a>
                </li>
                <li class="nav-item" role="tab">
                    <a class="nav-link" data-toggle="tab" href="#tabs-3">Front-end</a>
                </li>
                <li class="nav-item" role="tab">
                    <a class="nav-link" data-toggle="tab" href="#tabs-4">Infra &amp; Devops</a>
                </li>
                <li class="nav-item" role="tab">
                    <a class="nav-link" data-toggle="tab" href="#tabs-5">eCommerce</a>
                </li>
                <li class="nav-item" role="tab">
                    <a class="nav-link" data-toggle="tab" href="#tabs-6">CMS</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active show" id="tabs-1" role="tabpanel">
                    <div class="tech-wrapper">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/iOS.svg" width="66px" height="66px" alt="iOS"
                                        data-lazy-src="../../../assets/images/tech/iOS.svg" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/iOS.svg" width="66px" height="66px"
                                            alt="iOS"></noscript>
                                    <p class="m-0">iOS</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Android.svg" width="66px" height="66px"
                                        alt="Android" data-lazy-src="../../../assets/images/tech/Android.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Android.svg" width="66px" height="66px"
                                            alt="Android"></noscript>
                                    <p class="m-0">Android </p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Flutter.svg" width="66px" height="66px"
                                        alt="Flutter" data-lazy-src="../../../assets/images/tech/Flutter.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Flutter.svg" width="66px" height="66px"
                                            alt="Flutter"></noscript>
                                    <p class="m-0">Flutter</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/React-Native.svg" width="66px" height="66px"
                                        alt="React-Native" data-lazy-src="../../../assets/images/tech/React-Native.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/React-Native.svg" width="66px"
                                            height="66px" alt="React-Native"></noscript>
                                    <p class="m-0">React Native</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Ionic.svg" width="66px" height="66px"
                                        alt="Ionic" data-lazy-src="../../../assets/images/tech/Ionic.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Ionic.svg" width="66px" height="66px"
                                            alt="Ionic"></noscript>
                                    <p class="m-0">Ionic</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tabs-2" role="tabpanel">
                    <div class="tech-wrapper">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Node-JS.svg" width="66px" height="66px"
                                        alt="Node-JS" data-lazy-src="../../../assets/images/tech/Node-JS.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Node-JS.svg" width="66px" height="66px"
                                            alt="Node-JS"></noscript>
                                    <p class="m-0">Node.js</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/microsoft-dot-net.svg" width="66px"
                                        height="66px" alt="microsoft-dot-net"
                                        data-lazy-src="../../../assets/images/tech/microsoft-dot-net.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/microsoft-dot-net.svg" width="66px"
                                            height="66px" alt="microsoft-dot-net"></noscript>
                                    <p class="m-0">.Net</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/java.svg" width="66px" height="66px"
                                        alt="java" data-lazy-src="../../../assets/images/tech/java.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/java.svg" width="66px" height="66px"
                                            alt="java"></noscript>
                                    <p class="m-0">Java</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Django.svg" width="66px" height="66px"
                                        alt="Django" data-lazy-src="../../../assets/images/tech/Django.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Django.svg" width="66px" height="66px"
                                            alt="Django"></noscript>
                                    <p class="m-0">Django</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/mean.webp" width="66px" height="66px" alt="MEAN"
                                        data-lazy-src="../../../assets/images/tech/MEAN.webp" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img src="../../../assets/images/tech/MEAN.webp"
                                            width="66px" height="66px" alt="MEAN"></noscript>
                                    <p class="m-0">MEAN</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/MERN.webp" width="66px" height="66px" alt="MERN"
                                        data-lazy-src="../../../assets/images/tech/MERN.webp" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img src="../../../assets/images/tech/MERN.webp"
                                            width="66px" height="66px" alt="MERN"></noscript>
                                    <p class="m-0">MERN</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Codeigniter.svg" width="66px" height="66px"
                                        alt="Codeigniter" data-lazy-src="../../../assets/images/tech/Codeigniter.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Codeigniter.svg" width="66px" height="66px"
                                            alt="Codeigniter"></noscript>
                                    <p class="m-0">CodeIgniter</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Symfony.svg" width="66px" height="66px" alt="Symfony"
                                        data-lazy-src="../../../assets/images/tech/Symfony.svg" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img src="../../../assets/images/tech/Symfony.svg"
                                            width="66px" height="66px" alt="Symfony"></noscript>
                                    <p class="m-0">Symfony</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Laravel.svg" width="66px" height="66px" alt="Laravel"
                                        data-lazy-src="../../../assets/images/tech/Laravel.svg" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img src="../../../assets/images/tech/Laravel.svg"
                                            width="66px" height="66px" alt="Laravel"></noscript>
                                    <p class="m-0">Laravel</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/PHP.svg" width="66px" height="66px" alt="PHP"
                                        data-lazy-src="../../../assets/images/tech/PHP.svg" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img src="../../../assets/images/tech/PHP.svg"
                                            width="66px" height="66px" alt="PHP"></noscript>
                                    <p class="m-0">PHP</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Express-js-1.svg" width="66px" height="66px"
                                        alt="Express js (1)" data-lazy-src="../../../assets/images/tech/Express-js-1.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Express-js-1.svg" width="66px" height="66px"
                                            alt="Express js (1)"></noscript>
                                    <p class="m-0">Express.js</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Go-lang-tech.svg" width="66px" height="66px"
                                        alt="Go lang- tech" data-lazy-src="../../../assets/images/tech/Go-lang-tech.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Go-lang-tech.svg" width="66px" height="66px"
                                            alt="Go lang- tech"></noscript>
                                    <p class="m-0">Golang</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tabs-3" role="tabpanel">
                    <div class="tech-wrapper">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Java-Script.svg" width="66px" height="66px"
                                        alt="JavaScript" data-lazy-src="../../../assets/images/tech/Java-Script.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Java-Script.svg" width="66px" height="66px"
                                            alt="JavaScript"></noscript>
                                    <p class="m-0">Javascript</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/React-Native.svg" width="66px" height="66px"
                                        alt="React Native" data-lazy-src="../../../assets/images/tech/React-Native.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/React-Native.svg" width="66px" height="66px"
                                            alt="React Native"></noscript>
                                    <p class="m-0">React.js</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Vue-JS.svg" width="66px" height="66px"
                                        alt="Vue-JS" data-lazy-src="../../../assets/images/tech/Vue-JS.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Vue-JS.svg" width="66px" height="66px"
                                            alt="Vue-JS"></noscript>
                                    <p class="m-0">Vue.js</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Angular-JS.svg" width="66px" height="66px"
                                        alt="Angular" data-lazy-src="../../../assets/images/tech/Angular-JS.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Angular-JS.svg" width="66px" height="66px"
                                            alt="Angular"></noscript>
                                    <p class="m-0">Angular.js</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tabs-4" role="tabpanel">
                    <div class="tech-wrapper">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Shape.svg" width="66px" height="66px" alt="Shape"
                                        data-lazy-src="../../../assets/images/tech/Shape.svg" data-ll-status="loaded"
                                        class="entered lazyloaded"><noscript><img src="../../../assets/images/tech/Shape.svg"
                                            width="66px" height="66px" alt="Shape"></noscript>
                                    <p class="m-0">AWS</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Google-Cloud.svg" width="66px" height="66px"
                                        alt="Google Cloud" data-lazy-src="../../../assets/images/tech/Google-Cloud.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Google-Cloud.svg" width="66px"
                                            height="66px" alt="Google Cloud"></noscript>
                                    <p class="m-0">Google Cloud</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Mask-group.webp" width="66px" height="66px"
                                        alt="Mask group" data-lazy-src="../../../assets/images/tech/Mask-group.webp"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Mask-group.webp" width="66px" height="66px"
                                            alt="Mask group"></noscript>
                                    <p class="m-0">CI/CD Pipeline</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Office-365.svg" width="66px" height="66px"
                                        alt="Office 365" data-lazy-src="../../../assets/images/tech/Office-365.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Office-365.svg" width="66px" height="66px"
                                            alt="Office 365"></noscript>
                                    <p class="m-0"> Office 365</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Mongodb-1.svg" width="66px" height="66px"
                                        alt="Mongodb (1)" data-lazy-src="../../../assets/images/tech/Mongodb-1.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Mongodb-1.svg" width="66px" height="66px"
                                            alt="Mongodb (1)"></noscript>
                                    <p class="m-0">MongoDB</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tabs-5" role="tabpanel">
                    <div class="tech-wrapper">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Magento.svg" width="66px" height="66px"
                                        alt="Magento" data-lazy-src="../../../assets/images/tech/Magento.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Magento.svg" width="66px" height="66px"
                                            alt="Magento"></noscript>
                                    <p class="m-0">Magento</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Shopify.svg" width="66px" height="66px"
                                        alt="Shopify" data-lazy-src="../../../assets/images/tech/Shopify.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Shopify.svg" width="66px" height="66px"
                                            alt="Shopify"></noscript>
                                    <p class="m-0">Shopify</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/WooCommerce.svg" width="66px" height="66px"
                                        alt="WooCommerce" data-lazy-src="../../../assets/images/tech/WooCommerce.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/WooCommerce.svg" width="66px" height="66px"
                                            alt="WooCommerce"></noscript>
                                    <p class="m-0">WooCommerce</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/open-cart.svg" width="66px" height="66px"
                                        alt="open-cart" data-lazy-src="../../../assets/images/tech/open-cart.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/open-cart.svg" width="66px" height="66px"
                                            alt="open-cart"></noscript>
                                    <p class="m-0">Open Cart</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Odoo-ERP.svg" width="66px" height="66px"
                                        alt="Odoo ERP" data-lazy-src="../../../assets/images/tech/Odoo-ERP.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Odoo-ERP.svg" width="66px" height="66px"
                                            alt="Odoo ERP"></noscript>
                                    <p class="m-0">Odoo</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/BigCommerce-Integration.svg" width="66px"
                                        height="66px" alt="BigCommerce Integration"
                                        data-lazy-src="../../../assets/images/tech/BigCommerce-Integration.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/BigCommerce-Integration.svg" width="66px"
                                            height="66px" alt="BigCommerce Integration"></noscript>
                                    <p class="m-0">Bigcommerce</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tabs-6" role="tabpanel">
                    <div class="tech-wrapper">
                        <div class="row">
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Wordpress-1.svg" width="66px" height="66px"
                                        alt="Wordpress" data-lazy-src="../../../assets/images/tech/Wordpress-1.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Wordpress-1.svg" width="66px" height="66px"
                                            alt="Wordpress"></noscript>
                                    <p class="m-0">WordPress</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Drupal.svg" width="66px" height="66px"
                                        alt="Drupal" data-lazy-src="../../../assets/images/tech/Drupal.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Drupal.svg" width="66px" height="66px"
                                            alt="Drupal"></noscript>
                                    <p class="m-0">Drupal</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/strapi-icon.svg" width="66px" height="66px"
                                        alt="strapi-icon" data-lazy-src="../../../assets/images/tech/strapi-icon.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/strapi-icon.svg" width="66px" height="66px"
                                            alt="strapi-icon"></noscript>
                                    <p class="m-0">Strapi</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/Sitecore.svg" width="66px" height="66px"
                                        alt="Sitecore" data-lazy-src="../../../assets/images/tech/Sitecore.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/Sitecore.svg" width="66px" height="66px"
                                            alt="Sitecore"></noscript>
                                    <p class="m-0">Sitecore</p>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-4 col-6">
                                <a routerLink="/contact">
                                    <img src="../../../assets/images/tech/webfloe_66x66.svg" width="66px" height="66px"
                                        alt="webfloe_66x66" data-lazy-src="../../../assets/images/tech/webfloe_66x66.svg"
                                        data-ll-status="loaded" class="entered lazyloaded"><noscript><img
                                            src="../../../assets/images/tech/webfloe_66x66.svg" width="66px" height="66px"
                                            alt="webfloe_66x66"></noscript>
                                    <p class="m-0">Webflow</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>