<div class="breadcrumbs m-40">
    <a href="/" class="link bold">Home</a>
    <div class="mx-2 d-flex">
        <span class="fa fa-chevron-right"></span>
    </div>
    <a href="/" class="link">Contact Us</a>
</div>
<div class="sec mar-above">
    <div class="wrapper-tiitle-subtittle">
        <h1 class="h1 dsv">Contact Us <span class="text-span-26"></span></h1>
        <div class="body-text centered mt-4">We look forward to partnering with you. Let’s collaborate.
        </div>
    </div>
</div>
<div class="sec color mb-0">
    <div class="contact-wrapper">
        <div class="contact-info">
            <div class="tittle-contact">
                <h2 class="h2 dsv">connect with us</h2>
            </div>
            <div class="desc-contact m-24">
                <div class="body-text d-block">Have an idea on your mind that you think dishivi can bring to
                    life? We
                    would love to hear it from you!<br><br>For business inquiries:
                    <br>
                    Email us at
                    <a href="mailto:hello@dishivi.com?subject=hello%40dishivi.com" class="text-decoration-none">
                        <span class="text-span-40">dev@dishivi.com</span>
                    </a>
                    <br><br>
                    <div class="heading-contact-last">
                        <h3 class="h3 mb-4 dsv">Our Address</h3>
                    </div>
                    <a href="#" target="_blank" class="address-maps-link w-inline-block">
                        <div class="icon-title-contact">
                            <div class="contact-icon">
                                <span class="fa fa-map-marker"></span>
                            </div>
                            <h3 class="h3 dsv letter-capital cityname">dishivi India</h3>
                        </div>
                        
                        <div class="address-wrapper-contact m-16 m-29">
                            <div class="body-text con-address">
                                5 & 6, First Floor, Pramukh complex,<br> Chanakyapuri, Ahmedabad,<br> Gujarat<br>
                            </div>
                        </div>
                    </a>
                    <div class="address-wrapper-contact m-16 m-29">
                        <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+91-777-886-7430</a></div>
                    </div>




                </div>
                <div class="body-text hide-temp"></div>
            </div>
            <div class="desc-2-contact m-40 hide-temp">
                <div class="heeading-3-contact">
                    <h3 class="h3 dsv"></h3>
                </div>
                <div class="desc-2-contact m-24">
                    <div class="body-text"></div>
                    <div class="body-text"></div>
                </div>
            </div>
            <div class="desc-3-contact m-40">
                <div class="heading-contact-last">
                    <h3 class="h3 dsv">Follow us on Social Media</h3>
                </div>
                <div class="icon-social m-24"><a href="https://www.linkedin.com/company/96022268/admin/feed/posts/" target="_blank"
                        class="w-inline-block">
                        <div class="social-icon">
                            <span class="fa fa-linkedin"></span>
                        </div>
                    </a>
<!--                     <a href="https://twitter.com/dishiviCloud" target="_blank" class="w-inline-block">
                        <div class="social-icon">
                            <span class="fa fa-twitter"></span>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/dishivicloud" target="_blank" class="w-inline-block">
                        <div class="social-icon">
                            <span class="fa fa-facebook"></span>
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
        <form class="contact-form m-40 no-44 w-form">
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email">Name:</label>
                                <input type="text" class="form-control" placeholder="Enter Name">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email">Company:</label>
                                <input type="text" class="form-control" placeholder="Enter Company">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" placeholder="Enter email" id="email">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="pwd">Phone:</label>
                                <input type="text" class="form-control" placeholder="Enter Phone" id="phone">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="pwd">Comment:</label>
                        <textarea class="form-control" placeholder="Enter Comment" id="comment"></textarea>
                    </div>
                </div>

                <div class="col-sm-4 align-self-center">
                    <a class="btn btn-primary btn-sm ml-0 mt-3" href="#"><span>Submit</span></a>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- <div class="sec mar-big">
    <div class="heading-wrapper">
        <h2 class="h2 centered dsv">our presence</h2>
    </div>
    <div class="address-wrapper m-40">
        <div class="address-card">
            <a href="#" target="_blank" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi Silicon Valley</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">Silicon Valley - HQ2570 North 1st Street, STE 200
                        San Jose, CA - 95131, USA</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" target="_blank" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi Ahmedabad</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">A/1402, Mondeal Heights,S.G. Highway,
                        Ahmedabad,380015, India</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi nEW yORK</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">33 Irving Place New York, NY 10003</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" target="_blank" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi Pune</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">209/1B/1A Range HillsRoad,
                        Ashoknagar(Shivajinagar), Pune, Maharashtra 411020, India</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi London</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">Venture X, 1 Ariel Way, London W12 7SL</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" target="_blank" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi Vadodara</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">3rd floor, 1965, Alembic City,Alembic Rd, Gorwa,
                        Vadodara, Gujarat - 390003</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" target="_blank" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi Bengaluru</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">The HUB, Ground Floor, Unit 1, Block
                        B,Marathahalli - Sarjapur Rd, Bellandur, Bengaluru, Karnataka 560102</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
        <div class="address-card"><a href="#" target="_blank" class="address-maps-link w-inline-block">
                <div class="icon-title-contact">
                    <div class="contact-icon">
                        <span class="fa fa-map-marker"></span>
                    </div>
                    <h3 class="h3 dsv letter-capital cityname">dishivi RAJKOT</h3>
                </div>
                <div class="address-wrapper-contact m-16 m-29">
                    <div class="body-text con-address">4th Floor, Sterling Plaza,150 ft. Ring Road,Nr.
                        Indira Circle, Rajkot, Gujarat - 360005</div>
                </div>
            </a>
            <div class="address-wrapper-contact m-16 m-29">
                <div class="body-text-bold black dark purple"><a href="#" class="phone-link">+1-999-999-9999</a></div>
            </div>
        </div>
    </div>
</div> -->