import { Component, OnInit } from '@angular/core';
import { Slick } from 'ngx-slickjs';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  arrayLength = 10;

  config = {
    arrows: false,
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1
   }

  getArray(count: number) {
    return new Array(count)
  }
  

}
