import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutpage',
  templateUrl: './aboutpage.component.html',
  styleUrls: ['./aboutpage.component.css']
})
export class AboutpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  arrayLength = 10;

  config = {
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    dots: true,
    responsive: [{
            breakpoint: 991,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]

   }

  getArray(count: number) {
    return new Array(count)
  }

}
