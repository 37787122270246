<div class="nav-container">
    <nav class="sina-nav mobile-sidebar navbar-transparent navbar-fixed" data-top="40" data-md-top="40" data-xl-top="40">
        <div class="container-fluid">

            <div class="search-box">
                <a routerLink="/" class="navbar-brand logo">
                    <img class="img-fluid" alt="Image Alternative Text" src="../../../assets/images/logo-dishivi.png" />
                </a>
            </div>
            <!-- .search-box -->

            <div class="extension-nav">

            </div>
            <!-- .extension-nav -->

            <div class="sina-nav-header social-on">

                <a class="sina-brand" href="#">
                    <img src="../../../assets/images/logo-dishivi.png" alt="" class="logo-primary">
                    <img src="../../../assets/images/logo-dishivi.png" alt="" class="logo-secondary">
                </a>
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i class="fa fa-bars"></i>
                </button>
            </div>
            <!-- .sina-nav-header -->

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="navbar-menu">
                <ul class="sina-menu" data-in="fadeInLeft" data-out="fadeInOut">
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="about">About</a></li>

                    <li class="dropdown menu-item-has-mega-menu">
                        <a routerLink="service" class="dropdown-toggle" data-toggle="dropdown">Services</a>
                        <div class="mega-menu dropdown-menu">
                            <ul class="mega-menu-row" role="menu">
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/ideate.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">IDEATE
                                    </h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Digital Transformation</a></li>
                                        <li><a href="#">Modernization</a></li>
                                        <li><a href="#">Cloud Strategy</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/develop.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">DEVELOP</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Plateforms</a></li>
                                        <li><a href="#">Cloud Applications</a></li>
                                        <li><a href="#">Ecosystem</a></li>
                                        <li><a href="#">Infrastructure</a></li>
                                        <li><a href="#">Security</a></li>
                                        <li><a href="#">AI/ML & Analytics</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/automate.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">AUTOMATE</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">QE Automation</a></li>
                                        <li><a href="#">DevOps Automation</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/scale.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">SCALE</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Ecosystem Connectors</a></li>
                                        <li><a href="#">Multi-Cloud</a></li>
                                        <li><a href="#">Data Engineering</a></li>
                                        <li><a href="#">Concurrent Users</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/manage.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">MANAGE</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Tech Support</a></li>
                                        <li><a href="#">Site Reliability</a></li>
                                        <li><a href="#">MSP Support</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li><a routerLink="blogs">Blogs</a></li>

                    <!-- <li class="dropdown menu-item-has-mega-menu">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">Competencies</a>
                        <div class="mega-menu dropdown-menu">
                            <ul class="mega-menu-row" role="menu">
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/domainskills.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">
                                        DOMAIN SKILLS
                                    </h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Cloud Infrastructure</a></li>
                                        <li><a href="#">Cybersecurity</a></li>
                                        <li><a href="#">Networking</a></li>
                                        <li><a href="#">FinTech</a></li>
                                        <li><a href="#">Healthcare</a></li>
                                        <li><a href="#">Manufacturing</a></li>
                                        <li><a href="#">Retail & Logistics</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/techskills.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">TECH SKILLS</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Frontend</a></li>
                                        <li><a href="#">Backend</a></li>
                                        <li><a href="#">Microsoft</a></li>
                                        <li><a href="#">Databases</a></li>
                                        <li><a href="#">Quality</a></li>
                                        <li><a href="#">Analytics & AI/ML</a></li>
                                        <li><a href="#">DevOps</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/designskill.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">DESIGN SKILLS</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Multi-Cloud</a></li>
                                        <li><a href="#">Well-Architected</a></li>
                                        <li><a href="#">API-First</a></li>
                                        <li><a href="#">Event-Driven Architecture</a></li>
                                        <li><a href="#">Real-Time Systems</a></li>
                                        <li><a href="#">UX/UI Design</a></li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col main-menu-col">
                                    <div class="mega-menu-icon">
                                        <img src="../../../assets/images/menu/processskill.svg" loading="lazy" alt="">
                                    </div>
                                    <h4 class="mega-menu-col-title">Process SKILLS</h4>
                                    <ul class="sub-menu">
                                        <li><a href="#">Design Thinking</a></li>
                                        <li><a href="#">Agile Delivery</a></li>
                                        <li><a href="#">Production Worthy</a></li>
                                        <li><a href="#">Collaboration</a></li>
                                        <li><a href="#">IP Protection</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </li> -->
                    <!-- <li class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">Dropdown</a>
                        <ul class="dropdown-menu">
                            <li><a href="#">Custom Menu</a></li>
                            <li><a href="#">Custom Menu<p class="description">This is Description</p></a>
                            </li>
                            <li class="dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown">Dropdown</a>
                                <ul class="dropdown-menu">
                                    <li><a href="#">Custom Menu</a></li>
                                    <li><a href="#">Custom Menu</a></li>
                                    <li><a href="#">Custom Menu</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li> -->
                </ul>
                <!-- end row -->
            </div>

        </div>
        <div class="navbar-nav d-sm-inline-flex d-none">
            <a routerLink="contact" class="header-button ml-32">
                <span class="ico-phone"></span>
                <div class="body-text-bold dsv button-header">Connect</div>
            </a>
        </div>
        <!-- /.navbar-collapse -->
        <!-- .container -->
        <!-- Start widget-bar -->
        <div class="sina-nav-overlay off"></div>
        <div class="widget-bar">
            <a href="#" class="close-widget-bar"><i class="fa fa-times"></i></a>
            <div class="widget">
                <h6 class="title">Custom Pages</h6>
                <ul class="link">
                    <li><a href="#">About</a></li>
                    <li><a href="#">Services</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Portfolio</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
            </div>
            <div class="widget">
                <h6 class="title">Additional Links</h6>
                <ul class="link">
                    <li><a href="#">Retina Homepage</a></li>
                    <li><a href="#">New Page Examples</a></li>
                    <li><a href="#">Parallax Sections</a></li>
                    <li><a href="#">Shortcode Central</a></li>
                    <li><a href="#">Ultimate Font Collection</a></li>
                </ul>
            </div>
        </div>
        <!-- End widget-bar -->
    </nav>
</div>