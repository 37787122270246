<div class="breadcrumbs m-40">
    <a href="/" class="link bold">Home</a>
    <div class="mx-2 d-flex">
        <span class="fa fa-chevron-right"></span>
    </div>
    <a href="/blogs" class="link">Blogs</a>
</div>
<div class="sec mar-above">
    <div class="wrapper-tiitle-subtittle">
        <h1 class="h1 dsv">Blogs <span class="text-span-26"></span></h1>
<!--         <div class="body-text centered mt-4">We look forward to partnering with you. Let’s collaborate.
        </div> -->
    </div>
</div>
<!-- service Start  -->
<section class="service-wrapper">
    <div class="container">
        <div class="service-inner">
            <div class="row">
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image h-300">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/blog/ETL.jpg">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">ETL Introduction</h6>
                                <p>ETL is a traditionally accepted way for organizations to combine data from multiple systems into a single database, data store, data warehouse, or data lake.</p>
                            </div>
                        </div>
                    </a>
                </div>
<!--                 <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-social-media.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Hotel Social Media Marketing</h6>
                                <p>Building the online presence that helps you maintain your position in the market.</p>
                            </div>
                        </div>
                    </a>
                </div> -->

            </div>
        </div>
    </div>
</section>
<!-- service End  -->