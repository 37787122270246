<div class="footer m-120">
    <div class="container d-container mx-auto">
        <div class="footer-menu pa-40">
            <div class="logo-text-social width-264 mb-4">
                <a routerLink="/" aria-current="page" class="d-inline-block d--current">
                    <div class="logo-content mb-4">
                        <img src="../../../assets/images/logo-white.png" loading="lazy" alt="" class="image-19">
                    </div>
                </a>
                <div class="social m-48">
                    <a routerLink="/" target="_blank" class="social-link d-inline-block">
                    </a>
                    <a href="https://www.linkedin.com/company/96022268/admin/feed/posts/" target="_blank" class="social-link d-inline-block">
                        <img src="../../../assets/images/social/linkedin.svg" loading="lazy" alt="DISHIVI LinkedIn">
                    </a>
<!--                     <a routerLink="/" target="_blank" class="social-link d-inline-block">
                        <img src="../../../assets/images/social/twitter.svg" loading="lazy" alt="DISHIVI Twitter">
                    </a>
                    <a routerLink="/" target="_blank" class="social-link d-inline-block">
                        <img src="../../../assets/images/social/facebook.png" loading="lazy" alt="DISHIVI Facebook">
                    </a> -->
                </div>
                <div class="button-footer-ds m-32"><a routerLink="/" class="button-fooder-ds new d-button">get in
                        touch</a></div>
            </div>
            <div clas="grid-one" class="div-block-54">
                <div class="footer-link-block">
                    <div class="body-text-bold dsv">Quick Links</div>
                    <div class="submenu m-16">
                        <a routerLink="/" class="footer-link d-inline-block">
                            <div class="body-text white footer-hover"><strong class="bold-text-7">FinTech</strong></div>
                        </a>
                        <a routerLink="/" class="footer-link d-inline-block">
                            <div class="body-text white footer-hover"><strong class="bold-text-8">Cybersecurity</strong></div>
                        </a>
                        <a routerLink="/" class="footer-link d-inline-block">
                            <div class="body-text white footer-hover"><strong class="bold-text-9">QE
                                    Automation</strong></div>
                        </a>
                        <a routerLink="/" class="footer-link d-inline-block">
                            <div class="body-text white footer-hover"><strong class="bold-text-9">AI/ML</strong>
                            </div>
                        </a>
                        <a routerLink="/" target="_blank" class="footer-link d-inline-block">
                            <div class="body-text white footer-hover">HealthTech</div>
                        </a>
                        <a routerLink="/" target="_blank" class="footer-link d-inline-block">
                            <div class="body-text white footer-hover">CloudOps</div>
                        </a>
                    </div>
                </div>

                <div class="footer-link-block width-280 m-32">
                    <div class="body-text-bold dsv">Contact US</div>
                    <div class="submenu m-16 width-258">
                        <a href="mailto:hello@DISHIVI.com" class="link_location">
                            <div class="footer-icon">
                                <span class="fa fa-envelope"></span>
                            </div>
                            <div class="body-text white footer-hover email-text-footer">dev@dishivi.com
                            </div>
                        </a>
                        <!-- <a routerLink="/" class="link_location">
                            <div class="footer-icon">
                                <span class="fa fa-phone"></span>
                            </div>
                            <div class="body-text white footer-hover">+1-999-999-9999</div>
                        </a>
                        <a routerLink="/" class="link_location">
                            <div class="footer-icon">
                                <span class="fa fa-phone"></span>
                            </div>
                            <div class="body-text white footer-hover">+91 9999999999</div>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="divider m-95 divider-footer-div"></div>
        <div class="location-div">
            <div class="text-block-25">Location</div>
            <div class="location-inside-div">
                <img src="../../../assets/images/icon-location.svg" loading="lazy" alt="" class="location-icon">
                <div class="text-block-25">India :</div>
                <a routerLink="/" class="link-location">
                    5 & 6, First Floor, Pramukh complex, Chanakyapuri, Ahmedabad, Gujarat
                </a>
            </div>
        </div>
        <div class="divider m-95 divider-footer-div"></div>
        <div class="other-info m-48">
            <div class="disck">
                <div class="subline">
                    <a routerLink="/" class="link-rules-footer d-inline-block">
                        <div class="captions hover-caption">Legal Disclaimer</div>
                    </a>
                    <a routerLink="/" class="link-rules-footer d-inline-block">
                        <div class="captions hover-caption">Privacy Policy</div>
                    </a>
                    <a routerLink="/" class="link-rules-footer d-inline-block">
                        <div class="captions hover-caption">Cookie Policy</div>
                    </a>
                </div>
                <div class="subline">
                    <div class="captions">© 2023, DISHIVI. All Rights Reserved</div>
                </div>
            </div>
        </div>
    </div>
</div>