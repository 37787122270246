<div class="breadcrumbs m-40">
    <a href="/" class="link bold">Home</a>
    <div class="mx-2 d-flex">
        <span class="fa fa-chevron-right"></span>
    </div>
    <a href="/" class="link">Services</a>
</div>
<div class="sec mar-above">
    <div class="wrapper-tiitle-subtittle">
        <h1 class="h1 dsv">Services <span class="text-span-26"></span></h1>
        <div class="body-text centered mt-4">We look forward to partnering with you. Let’s collaborate.
        </div>
    </div>
</div>


<!-- banner Start  -->
<section class="banner-wrapper service-banner-wrapper h-auto">
    <div class="container">
        <div class="banner-inner">
            <div class="banner-item">
                <div class="row mx-0">
                    <!-- <div class="col-lg-6 px-0 order-1 order-lg-2">
                        <figure class="banner-img">
                            <img alt="Image Alternative Text" class="banner-img img-fluid" src="../../assets/images/banner.png">
                        </figure>
                    </div> -->
                    <div class="col-xl-12 px-0 order-1 align-self-center">
                        <div class="banner-info mb-4">
                            <h1 class="banner-title">How can we help you grow your Business?
                            </h1>
                            <p class="mb-4">At Dishivi, we design and develop highly-functional websites for a grand
                                chain, a boutique, or an inn utilizing the best technology and market standards and
                                delivering within the timeframe. Our marketing
                                strategies will lead you to more business. We help your be exceptional in the market.
                            </p>
                            <a href="get-in-touch.html" class="btn btn-primary">
                                <span class="mr-2">Start a Project</span>
                                <img class="img-fluid" src="../../assets/images/icon-arrow-right.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>
</section>
<!-- banner End  -->

<!-- service Start  -->
<section class="service-wrapper">
    <div class="container">
        <div class="service-inner">
            <div class="row">
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-webdesign.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Hotel Website Design and Development</h6>
                                <p>We develop highly-functional and dynamic websites that attract more customers to your
                                    hotel.</p>
                            </div>
                        </div>
                    </a>
                </div>
<!--                 <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-seo.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Hotel SEO Marketing</h6>
                                <p>Optimizing Hotel websites and helping your hotel website rank on the top with
                                    comprehensive SEO strategies.</p>
                            </div>
                        </div>
                    </a>
                </div> -->
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-social-media.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Hotel Social Media Marketing</h6>
                                <p>Building the online presence that helps you maintain your position in the market.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-content-writing.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Hotel Content Writing</h6>
                                <p>We write well-research, original, engaging, and keyword-rich content that fosters
                                    leads.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-reputation-management.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">ORM – Online Reputation Management</h6>
                                <p>We help you maintain your customer retention and build a positive image by replying
                                    to feedbacks received on google and Facebook of your hotel.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-hotel-photography.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Hotel Photography</h6>
                                <p>Vivamus mollis diam quis erat tincidt, vitae placerat nisl dictum.We capture unique
                                    and appealing photographs and videos of your hotel that attract your site visitors.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-md-6 m-b-30">
                    <a routerLink="/contact" class="service-item">
                        <div class="service-image">
                            <figure class="service-img">
                                <img alt="Image Alternative Text" class="service-img img-fluid"
                                    src="../../assets/images/service-ppc.png">
                            </figure>
                            <div class="service-info">
                                <h6 class="service-title">Paid Advertising (PPC)</h6>
                                <p>We strategize ads on Facebook, Bing and Google that help you create online visibility
                                    and foster leads.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- service End  -->

<!-- services-overview Start  -->
<section class="services-overview-wrapper mb-4">
    <div class="container">
        <div class="services-overview-inner">
            <div class="section-heading">
                <h2 class="mb-0">What makes us Unique?</h2>
                <div class="row">
                    <div class="col-md-10">
                        <div class="normal-content space-60">
                            <p class="my-3">The transparent, client-centric, and focused approach to discover, design,
                                and develop quality software products for web and mobile platforms. This proven approach
                                fosters seamless collaboration to develop complex systems in the most secure and
                                efficient way. </p>
                        </div>
                        <h4 class="mb-0">Our workflow is standardized but filled with innovative thinking and unmatched
                            expertise at every stage.</h4>
                    </div>
                </div>
            </div>
            <img class="img-fluid" src="../../assets/images/services.png" />

        </div>
    </div>
</section>
<!-- services-overview End  -->