import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicepage',
  templateUrl: './servicepage.component.html',
  styleUrls: ['./servicepage.component.css']
})
export class ServicepageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
