
<div class="breadcrumbs m-40">
    <a href="/" class="link bold">Home</a>
    <div class="mx-2 d-flex">
        <span class="fa fa-chevron-right"></span>
    </div>
    <a href="/" class="link">About Us</a>
</div>
<div class="sec mar-above">
    <div class="wrapper-tiitle-subtittle">
        <h1 class="h1 dsv">About Us <span class="text-span-26"></span></h1>
        <div class="body-text centered mt-4">We look forward to partnering with you. Let’s collaborate.
        </div>
    </div>
</div>


<!-- why-us Start  -->
<!-- <section class="why-us-wrapper">
    <div class="container">
        <div class="section-header mb-0">
            <div class="row">
                <div class="col-lg-8">

                    <div class="subtitle">History</div>
                    <h2 class="title">Know our story
                    </h2>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6">

                            <p class="text-grey">In 1991, our founder Nimesh Dinubhai came to the United States with his family and settled in Phoenix. In 2003, Nimesh completed his B.A. in Computer Information System from Devry University, Phoenix. Being a hotelier,
                                he understood how a website and effective marketing strategies played a crucial role in attracting hotel business. And that's how the idea of opening a customer-centric company focused on hotel website design and hotel
                                marketing services originated in 2003.</p>

                        </div>
                        <div class="col-lg-6">

                            <p class="text-grey">Dishivihas established its identity by serving the hotel fraternity for the past 16 years and is the only web development and marketing company in Phoenix, Arizona. Today, Dishiviis American Hotel Owners Association's
                                preferred service provider for hotel website design, hotel website development, hotel marketing, hotel content, and hotel photography services.</p>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
 -->
<!-- why-us End  -->

<!-- why-us Start  -->
<section class="why-us-wrapper">
    <div class="container">
        <div class="section-header mb-0">
            <div class="row">
                <div class="col-lg-8">

                    <div class="subtitle">why us?</div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <h2 class="title">Our Mission
                            </h2>
                            <p class="text-grey">
                                At DISHIVI, our mission is to leverage technology as a catalyst for transformative solutions. Our commitment lies in understanding and addressing the unique needs of our clients. We partner with them to provide tailored, reliable, and scalable IT solutions that exceed expectations.
                            </p>

                        </div>
                        <div class="col-lg-6">
                            <h2 class="title">Our Vision
                            </h2>
                            <p class="text-grey">At DISHIVI, our vision is to be a global leader at the forefront of technological innovation, empowering businesses and communities worldwide. Striving to be pioneers in emerging technologies, we envision a future where our solutions revolutionize industries, drive efficiencies, and inspire progress.

</p>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- why-us End  -->

<!-- heighlights Start  -->
<section class="heighlights-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 m-b-30">
                <div class="heighlights-item">
                    <h3 class="title"><span class="counter">100</span>+</h3>
                    <h6 class="title">Total Clients
                    </h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-30">
                <div class="heighlights-item">
                    <h3 class="title"><span class="counter">50</span>+</h3>
                    <h6 class="title">Ongoing Projects
                    </h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-30">
                <div class="heighlights-item">
                    <h3 class="title"><span class="counter">12</span>+</h3>
                    <h6 class="title">Countries
                    </h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 m-b-30">
                <div class="heighlights-item">
                    <h3 class="title"><span class="counter">99</span>+</h3>
                    <h6 class="title">Good Reviews
                    </h6>
                </div>
            </div>

        </div>

    </div>
</section>
<!-- heighlights End  -->


<!-- some-people Start  -->
<!-- <section class="some-people-wrapper">
    <div class="position-relative ">
        <div class="container">
            <div class="some-people-inner">
                <div class="section-header text-center mb-5">
                    <h3 class="title">Know our Team behind your Success </h3>
                    <p class="text-grey">At Websrefresh, we value our client’s belief and investment in us. Our team is highly professional and is dedicated to delivering real-time results. Here’s why we are better:.
                    </p>
                </div>
                <div class="some-people-list">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-1.jpg" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Nimesh Dinubhai
                                    </h6>
                                    <div class="some-people-desc">Founder - President
                                    </div>
                                    <p title="The man behind Websrefresh, Nimesh is a techie, a hotelier and a dedicated digital media professional with 15+ years of experience in the hotel and digital marketing. His vision of Websrefresh’s that he founded in 2013 is to help hotel brands globally grow bigger and richer.">
                                        The man behind Websrefresh, Nimesh is a techie, a hotelier and a dedicated digital media professional with 15+ years of experience in the hotel and digital marketing. His vision of Websrefresh’s that he founded in 2013 is to help hotel brands globally
                                        grow bigger and richer.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-2.jpg" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Naveen Sharma
                                    </h6>
                                    <div class="some-people-desc">Marketing Strategist
                                    </div>
                                    <p title="Naveen is an astute marketing strategist with 13 years of marketing journey with Websrefresh. He translates clients’ expectations to the team and ensures to meet the deliverables. Also the media coach and assists the Websrefresh’s team in building clients.">
                                        Naveen is an astute marketing strategist with 13 years of marketing journey with Websrefresh. He translates clients’ expectations to the team and ensures to meet the deliverables. Also the media coach and assists the Websrefresh’s team in building clients.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-3.jpg" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Kashyap Basu
                                    </h6>
                                    <div class="some-people-desc">Chief Technical Officer
                                    </div>
                                    <p title="With strong Multimedia, Web Design & Animation background, Kashyap leads the client projects. As chief technology officer Kashyap takes full care of clients’ tech requirements from concept development, site planning and architecture, to design and media planning and implementation.">
                                        With strong Multimedia, Web Design & Animation background, Kashyap leads the client projects. As chief technology officer Kashyap takes full care of clients’ tech requirements from concept development, site planning and architecture, to design and media
                                        planning and implementation.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-4.png" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Priya Patel</h6>
                                    <div class="some-people-desc">DishiviContent Wizard
                                    </div>
                                    <p title="Priya is the wordsmith who creates powerful content for Webrefresh’s clients. Priya studied business at Memphis State University and soon bought her first hotel in West Texas. As a hotelier, she understands the industry requirement and creates great online value through her content marketing skills.">
                                        Priya is the wordsmith who creates powerful content for Webrefresh’s clients. Priya studied business at Memphis State University and soon bought her first hotel in West Texas. As a hotelier, she understands the industry requirement and creates great online
                                        value through her content marketing skills.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-5.jpg" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Brand Strategist & Coach

                                    </h6>
                                    <div class="some-people-desc">Web Strategist

                                    </div>
                                    <p title="Sunita Biddu is an expert web strategist who plans websites that make high revenues. With over 12 years of industry experience, Sunita uses fine website architectural skills and SEO strategies to help build websites brand assets with high domain authority.">Sunita Biddu is an expert web strategist who plans websites that make high revenues. With over 12 years of industry experience, Sunita uses fine website architectural skills and SEO strategies to help build websites
                                        brand assets with high domain authority.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-6.png" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Souhil Benammour

                                    </h6>
                                    <div class="some-people-desc">Chief Server Technical Officer

                                    </div>
                                    <p title="Souhil is our Chief Server Technical Officer and his job is to upgrade the infrastructure and hosting experience at Dishiviby using different Microsoft solutions and technologies. With 25+ years of experience in IT, his core strengths are in development of web applications for the hotel and real estate industry.">
                                        Souhil is our Chief Server Technical Officer and his job is to upgrade the infrastructure and hosting experience at Dishiviby using different Microsoft solutions and technologies. With 25+ years of experience in IT, his core strengths are in development
                                        of web applications for the hotel and real estate industry.

                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-7.jpg" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Mark Zemnick

                                    </h6>
                                    <div class="some-people-desc">Google Trusted Photographer

                                    </div>
                                    <p title="Mark is our chief photographer and his unique tell-a-story style of photography helps Websrefresh’s clients’ hotels sites stand apart. With over 20+ years of experience, Mark not only clinched a position with the Google Street View I Trusted Program in January of 2012 but also won the prestigious title of Google #1 Top Performer in Arizona.">
                                        Mark is our chief photographer and his unique tell-a-story style of photography helps Websrefresh’s clients’ hotels sites stand apart. With over 20+ years of experience, Mark not only clinched a position with the Google Street View I Trusted Program in
                                        January of 2012 but also won the prestigious title of Google #1 Top Performer in Arizona.</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 m-b-30">
                            <div class="some-people-item">
                                <div class="some-people-image">
                                    <img class="img-fluid" src="../../assets/images/people-8.jpg" />
                                </div>
                                <div class="some-people-info">
                                    <h6 class="some-people-title">Content Writer

                                    </h6>
                                    <div class="some-people-desc">DishiviContent Wizard
                                    </div>
                                    <p title="Originally from India, Maitri is a Computer Engineer by Profession and a blogger and content writer by passion. She moved to the USA in 2019 after her marriage. While exploring places and events around, she realized she had an interest in the Hospitality Industry in the USA. That’s when she started producing content for the Hotel Industry. Maitri is a voracious reader and also strongly believes in motivating others.">
                                        Originally from India, Maitri is a Computer Engineer by Profession and a blogger and content writer by passion. She moved to the USA in 2019 after her marriage. While exploring places and events around, she realized she had an interest in the Hospitality
                                        Industry in the USA. That’s when she started producing content for the Hotel Industry. Maitri is a voracious reader and also strongly believes in motivating others.</p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- some-people End  -->

<!-- customer-says Start  -->
<section class="customer-says-wrapper">
    <div class="container">
        <div class="customer-says-inner">
            <div class="section-header text-center mb-5">
                <div class="subtitle">You’re in good company</div>
                <h2 class="title">Reviews from our Clients</h2>
                <p class="text-grey">What makes Dishivi a great company is a trust and appreciation from our clients.
                </p>
            </div>
            <div class="customer-says-list" slickContainer #slickController="slick" [slickConfig]="config">

                <div class="customer-says-item" slickItem >
                    <div class="customer-says-text">
                        <p>From the outset, it was evident that DISHIVI operates with a high level of expertise and professionalism. Their team of experts demonstrated in-depth knowledge across various IT domains, offering insightful solutions tailored to our specific needs.”
                        </p>
                    </div>
                    <div class="customer-says-info">
                        <h6 class="customer-says-title">Nav</h6>
                        <div class="customer-says-desc">CEO at YCurb, USA</div>
                    </div>
                </div>

                <div class="customer-says-item" slickItem>
                    <div class="customer-says-text">
                        <p>What stood out the most was their customer-centric approach. They prioritized our satisfaction and success, making us feel like valued partners throughout the engagement. Their dedication to our project's success was evident in every interaction.”
                        </p>
                    </div>
                    <div class="customer-says-info">
                        <h6 class="customer-says-title">Mayur</h6>
                        <div class="customer-says-desc">Wheaton Advisors</div>
                    </div>
                </div>

            </div>


        </div>
    </div>

</section>
<!-- customer-says End  -->


